.instructions {
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
}

.pwa {
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 500px) {
    .instructions {
        width: 70%;
        margin: auto;
    }
}

@media screen and (min-width: 750px) {
    .instructions {
        width: 50%;
        margin: auto;
    }
}

@media screen and (min-width: 1000px) {
    .instructions {
        width: 20%;
        margin: auto;
    }
}

.instructions li {
    color: #485460;
}

.InstruktionText {
    font-weight: 500;
    text-align: center;
    color: #3c40c6;
}